import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mgl-timeline-entry-header',
  templateUrl: './timeline-entry-header.component.html',
  styleUrls: ['./timeline-entry-header.component.scss']
})
export class MglTimelineEntryHeaderComponent {
  
}
